import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useProgramsList(root) {
  // test
  const toast = useToast()

  const refInvoiceListTable = ref(null)
  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Program Name', sortable: true },
    { key: 'date_start', label: 'Start date', sortable: true },
    { key: 'date_end', label: 'End date', sortable: true },
    // { key: 'applicants', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]

  const defaultPerPage = Number(localStorage.getItem('programPerPage'))
  const perPage = ref(defaultPerPage || DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const filterLanguageOptions = ['English', 'Hebrew']
  const filterGenderOptions = ['Boys', 'Girls']
  const filterStatusOptions = ['Active', 'Inactive']
  const searchQuery = ref('')
  const filterDataLanguage = ref('')
  const filterDataGender = ref('')
  const filterDataStatus = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  let debounceTimeout = ref(null)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const clearFilters = () => {
    filterDataLanguage.value = ''
    filterDataGender.value = ''
    filterDataStatus.value = ''
  }

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, filterDataLanguage, filterDataGender, filterDataStatus, programId],
    ([currentPageVal, perPageVal, searchQueryVal, filterDataLanguageVal, filterDataGenderVal, filterDataStatusVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal, filterDataLanguagePrevVal, filterDataGenderPrevVal, filterDataStatusPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-program', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-program', 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-program', 'searchQuery', searchQueryVal)
        }, 250)
      }
      if (filterDataLanguageVal !== filterDataLanguagePrevVal) {
        pushStateFiltersParams(root, 'admin-program', 'filterDataLanguage', filterDataLanguageVal)
      }
      if (filterDataGenderVal !== filterDataGenderPrevVal) {
        pushStateFiltersParams(root, 'admin-program', 'filterDataGender', filterDataGenderVal)
      }
      if (filterDataStatusVal !== filterDataStatusPrevVal) {
        pushStateFiltersParams(root, 'admin-program', 'filterDataStatus', filterDataStatusVal)
      }
      refetchData()
    })

  const fetchProgramList = (ctx, callback) => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      programId: programId.value,
    }
    if (searchQuery.value) payloadData.name = searchQuery.value
    if (filterDataGender.value) payloadData.gender = filterDataGender.value
    if (filterDataStatus.value) payloadData.status = filterDataStatus.value

    store
      .dispatch('app-program/fetchProgramList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta
        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching programs' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const cloneProgram = ID => axios
    .get(`auth/programs/${ID}/clone`)
    .then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success cloning program',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error cloning program',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const deleteProgram = ID => axios
    .delete(`auth/programs/${ID}`)
    .then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting program',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting program',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const exportCampers = queryParams => axios
    .get('/auth/campers/export', { params: queryParams })
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting Students',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const exportStaff = queryParams => axios
    .get('/auth/staffs/export', { params: queryParams })
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting Staff',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const getProgramFiles = ID => axios
    .get(`auth/programs/${ID}/files`)
    .then(response => response.data.data)
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error getting program files',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const fetchZipFiles = queryParams => axios
    .get(`auth/programs/${queryParams.program_id}/download-zip`, {
      params: queryParams.file_names,
      responseType: 'blob',
    })
    .then(response => response.data)
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error downloading file',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'danger'
    return 'primary'
  }

  const actionOptions = [
    { value: 'edit', title: 'Edit', link: 'admin-program-edit' },
    { value: 'application', title: 'Add Application', link: 'admin-application-add' },
    { value: 'campers', title: 'View Students', link: 'admin-campers' },
    { value: 'reports', title: 'View Application Report', link: 'admin-report-details' },
    { value: 'staff', title: 'View Staff', link: 'admin-staff' },
    { value: 'group', title: 'View Groups', link: 'admin-groups' },
  ]

  return {
    fetchProgramList,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    filterLanguageOptions,
    filterGenderOptions,
    filterStatusOptions,
    searchQuery,
    filterDataLanguage,
    filterDataGender,
    filterDataStatus,
    sortBy,
    totalItems,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,
    actionOptions,

    resolveStatusVariant,

    cloneProgram,
    deleteProgram,

    refetchData,
    clearFilters,
    exportCampers,
    exportStaff,
    getProgramFiles,
    fetchZipFiles,
  }
}
