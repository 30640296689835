<template>

  <div>

    <b-card>

      <h2 class="mb-2">
        Search Filter
      </h2>

      <!-- Table Top -->
      <b-row>

        <b-col
          cols="12"
          md="4"
          class="w-100"
        >
          <v-select
            v-model="filterDataGender"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterGenderOptions"
            :clearable="false"
            :placeholder="'Select Gender'"
          />
        </b-col>

        <b-col
          cols="12"
          md="8"
        >
          <div class="d-flex align-items-center">
            <b-button
              class="ml-1"
              variant="primary"
              @click="clearFilters"
            >
              <span class="mr-25 align-middle">Reset filters</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :value="perPage"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />

              <b-button
                class="ml-1"
                variant="primary"
                :to="{ name: 'admin-program-add' }"
              >
                <span class="mr-25 align-middle">Create Program</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="fetchProgramList"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <!-- Column: name -->
        <template #cell(name)="data">
          <b-avatar
            class="mr-1"
            size="32"
            :src="data.item.logo"
          />
          <b-link
            :to="{ name: 'admin-program-edit', params: { id: data.item.id } }"
          >{{ data.item.name }}</b-link>
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveStatusVariant(data.value)}`"
          >
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <template
              v-for="option in actionOptions"
            >
              <b-dropdown-item
                v-if="option.link"
                :key="option.value"
                @click="navigateToLink(option.link, data)"
              >
                <span>{{ option.title }}</span>
              </b-dropdown-item>
            </template>

            <b-dropdown-item
              @click="cloneProgram(data.item.id)"
            >
              <span>Clone Program</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="deleteProgram(data.item.id)"
            >
              <span>Delete Program</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="expCampers(data.item)"
            >
              <span>Export {{ camperStudent(2) }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="expStaff(data.item)"
            >
              <span>Export Staff</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal.bulk-download-files
              @click="chosenProgram = data.item"
            >
              <span>Bulk Download File</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
          <!-- Export Campers -->
          <b-modal
            id="export-campers"
            ref="export-campers"
            ok-title="Save"
            cancel-variant="outline-secondary"
            centered
            :title="`Export ${camperStudent(2)}`"
            no-close-on-backdrop
            @ok="saveCSV('campers')"
          >
            <div
              v-if="isLoading"
              class="text-center my-1"
            >
              <b-spinner class="align-middle" />
            </div>
            <span v-else>{{ csvName('campers') }}.csv</span>
          </b-modal>

          <!-- Export Staff -->
          <b-modal
            id="export-staff"
            ref="export-staff"
            ok-title="Save"
            cancel-variant="outline-secondary"
            centered
            title="Export Staff"
            no-close-on-backdrop
            @ok="saveCSV('staff')"
          >
            <div
              v-if="isLoading"
              class="text-center my-1"
            >
              <b-spinner class="align-middle" />
            </div>
            <span v-else>{{ csvName('staff') }}.csv</span>
          </b-modal>
        </b-row>
      </div>

    </b-card>

    <bulk-download-files-modal
      v-if="chosenProgram"
      :program-id="chosenProgram.id"
      :program-name="chosenProgram.name"
    />

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
  BDropdown, BDropdownItem, BAvatar, BBadge, BButton, BLink, BSpinner,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'

import vSelect from 'vue-select'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import store from '@/store'
import programStoreModule from '@/views/admin/program/programStoreModule'
import useProgramsList from '@/views/admin/program/program-list/useProgramsList'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import BulkDownloadFilesModal from '@/views/admin/program/program-list/BulkDownloadFilesModal.vue'
import router from '@/router'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
    BButton,
    BLink,
    BSpinner,

    vSelect,

    TableSpinner,
    BulkDownloadFilesModal,
  },
  mixins: [downloadExportFile],
  data() {
    return {
      isLoading: false,
      selectedProgramName: null,
      csvFile: null,
      chosenProgram: null,
    }
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-program'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, programStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchProgramList,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      searchQuery,
      filterDataGender,
      filterDataStatus,
      sortBy,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,

      filterGenderOptions,
      filterStatusOptions,

      statusFilter,
      actionOptions,

      refetchData,

      cloneProgram,
      deleteProgram,

      resolveStatusVariant,
      clearFilters,
      exportCampers,
      exportStaff,
    } = useProgramsList(root)

    return {
      fetchProgramList,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      sortBy,
      searchQuery,
      filterDataGender,
      filterDataStatus,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,

      filterGenderOptions,
      filterStatusOptions,

      statusFilter,
      actionOptions,

      refetchData,

      cloneProgram,
      deleteProgram,

      resolveStatusVariant,
      clearFilters,
      exportCampers,
      exportStaff,
    }
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.refetchData()
    },
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    csvName(type) {
      return `${this.selectedProgramName}-${type}-${moment().format('YYYY-MM-DD')}`
    },
    navigateToLink(link, data) {
      if (link === 'admin-report-details') {
        router.push({ name: link, query: { program_id: data.item.id, report_view_name: 'Application Report View by Program ID' } })
      } else {
        router.push({ name: link, params: { id: data.item.id, name: data.item.name, type: 'Camper' } })
      }
    },
    async expCampers(program) {
      this.isLoading = true
      this.selectedProgramName = program.name

      const formData = {
        programId: program.id,
      }

      this.$refs['export-campers'].show()

      await this.exportCampers(formData)
        .then(response => {
          if (response.status === 200) {
            this.csvFile = response.data
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async expStaff(program) {
      this.isLoading = true
      this.selectedProgramName = program.name

      const formData = {
        programId: program.id,
      }

      this.$refs['export-staff'].show()
      await this.exportStaff(formData)
        .then(response => {
          if (response.status === 200) {
            this.csvFile = response.data
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    saveCSV(type) {
      this.downloadFile(this.csvFile, this.csvName(type), 'csv')
    },
    updatePerPage(val) {
      localStorage.setItem('programPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('programPerPage')
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.searchQuery || ''
      this.filterDataLanguage = query.filterDataLanguage || ''
      this.filterDataGender = query.filterDataGender || ''
      this.filterDataStatus = query.filterDataStatus || ''
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .badge {
    text-transform: uppercase;
  }

  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
